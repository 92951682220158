import {twMerge} from 'tailwind-merge';

type Props = {
  icon: any;
  style?: React.CSSProperties;
  color?: string;
  className?: string;
  size?: number;
};

const RemixIcon = ({style, icon, color, className, size}: Props) => {
  const sizeStyle = size ? {width: size, height: size} : null;

  const IconComponent = icon;

  return (
    <div
      style={{fill: color, ...sizeStyle, ...style}}
      className={twMerge(
        'w-[24px] h-[24px] h-icon shrink-0 leading-none [&>svg]:fill-inherit [&>svg]:w-full [&>svg]:h-full',
        className
      )}
    >
      <IconComponent />
    </div>
  );
};

export default RemixIcon;
